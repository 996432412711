@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,700&family=Roboto:wght@300;400;500;700&display=swap');


@layer base {
  html {
    @apply bg-slate-100  h-screen;
    font-family: "Lato", sans-serif;
    font-family: "Roboto", sans-serif;
  }

  h1 {
    font-family: "Roboto", sans-serif;
    @apply text-3xl md:text-4xl font-bold mb-3;
  }
  h2 {
    @apply text-2xl md:text-3xl;
  }

  h3 {
    font-family: inherit;
    @apply text-xl md:text-2xl font-normal;
  }

  h4 {
    font-family: inherit;
    @apply text-lg md:text-xl;
  }

  h5 {
    @apply mt-3 text-base md:text-lg;
  }

  p {
    @apply p-3 mb-3;
  }

  section {
    @apply mb-14;
  }

  iframe {
    @apply w-full  md:w-[640px] md:h-[360px];
  }

  label {
    @apply uppercase text-xs;
  }

  form {
    @apply flex bg-white w-4/5 justify-center my-14 md:rounded-xl shadow-sm;
  }

  input,
  textarea {
    @apply shadow-inner p-3 my-1 mb-6 bg-slate-50;
  }

  footer {
    @apply bg-white flex flex-col items-center pt-6 pr-6 pb-0 pl-6 text-center;
  }
  .heading {
    @apply text-4xl lg:text-5xl
  }
  .link {
    @apply text-storeyGreen-100;
  }

  .link:hover {
    @apply underline;
  }

  .link.nav {
    @apply  3xl:text-xl;
  }

  .btn {
    @apply text-white text-sm md:text-base p-3 rounded-md  ;
    background: radial-gradient(ellipse, rgb(20 131 78 / var(--tw-bg-opacity)) 20%, #147a3d 100%);
    
  }
  .btn:hover {
    @apply underline decoration-solid;
    background: radial-gradient(ellipse, #147a3d 20%,  rgb(20 131 78 / var(--tw-bg-opacity)) 100%);
   
   
  }
  .btn:focus {
    @apply underline decoration-solid bg-[#084126];
  }

  .toggle-btn {
    @apply relative w-full h-full transition-colors duration-300 ease-in-out;
  }

  .buy-tool__btn {
    @apply m-1 xs:m-4 text-sm md:text-base p-3 rounded-md;
  }

  .buy-tool__btn:hover,
  .focused,
  .buy-tool__btn:active {
    @apply no-underline border-2 text-storeyGreen-100 border-storeyGreen-100 bg-storeyGreen-100/10;
  }

  .blog-group {
    @apply absolute top-0 flex items-center justify-center w-full transition ease-in-out duration-300 ;
  }

  .card {
    @apply ease-out shadow-md bg-white text-center w-full h-auto flex-auto max-w-2xl py-8 flex flex-col items-center lg:justify-between justify-between;
    transform: scale(1);
    transition-property: transform, box-shadow;
    transition-duration: 300ms; 
    transition-timing-function: ease-out; 
  }

  .card:hover {
    @apply cursor-pointer shadow-lg;
    transform:  scale(1.1);
    transition-duration: 300ms; 
    transition-timing-function: ease-in-out; 
  }
  
  .card:hover .btn {
    @apply  text-white ease-in-out transition-all duration-300;
    background: radial-gradient(ellipse, #147a3d 20%,  rgb(20 131 78 / var(--tw-bg-opacity)) 100%);
  }
  
  .green-card {
    @apply rounded-lg border-2 shadow-md border-storeyGreen-100 bg-storeyGreen-100/10;
  }

  .slider {
    @apply lg:touch-none
  }

  .dots {
    @apply bg-storeyGreen-100 opacity-70;
  }

  .dot--active {
    @apply bg-storeyGreen-100 opacity-100;
  }
  .form-email-phone {
    @apply flex flex-col;
  }
  .footer-links {
    @apply hover:underline hover:text-storeyGreen-200 decoration-solid decoration-storeyGreen-200;
  }

  .social-icon {
    @apply bg-white rounded-full;
  }

  .contact-form {
    background: radial-gradient(ellipse at bottom, #167e4b 0%, #116432 100%);
  }

  .benefit-icon svg path {

    stroke: green;
  }

  .toggle-container {
    @apply max-w-[1650px]
  }

  .remodel::before {
    content: "";
    display: block;
    padding-top: 80%;
  }

  .tags {
    @apply text-sm m-0 p-2 px-3 mx-2 bg-[#E5F1E0] bg-opacity-90 text-[#14834E] rounded-full w-fit;
   
   
  }

}